import axios from "axios";
import { makeServiceUrl } from "./makeServiceUrl";
import { getIdToken } from "./getIdToken";

export const createShortUrl = async (
  longUrl: string,
  shortId: string = ""
): Promise<any> => {
  try {
    const token = await getIdToken();
    const response = await axios.get(makeServiceUrl("/create-short-url"), {
      params: {
        url: longUrl,
        id: shortId
      },
      headers: { authorization: `Bearer ${token}` }
    });
    console.info("createShortUrl response: ", response.data);
    return response.data;
  } catch (err) {
    console.error("Error: ", err);
    return { success: false, message: "Unexpected error" };
  }
};
