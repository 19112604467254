import { getCollection } from "./getCollection";
import { ShortUrl } from "./ShortUrl";

export const connectShortUrls = async (data: any, setData: any) => {
  try {
    setData({ ...data, isFetching: true });
    getCollection().onSnapshot(sn => {
      const results: ShortUrl[] = [];
      console.log('connectShortUrls received #', sn.size)
      sn.forEach(function(doc) {
        const data = doc.data() as ShortUrl;
        results.push(data);
      });
      setData({ ...data, shortUrls: results, isFetching: false });
    });
  } catch (e) {
    console.error(e);
    setData({ ...data, isFetching: false });
  }
};
