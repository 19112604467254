import React, { useEffect, useState } from "react";
import { addProtocol } from "../api/addProtocol";
import { CenteredModal } from "../components/CenteredModal";
import { connectShortUrls } from "../api/connectShortUrls";
import { createShortUrl } from "../api/createShortUrl";
import { makeShortUrl } from "../api/makeShortUrl";
import { removeShortUrl } from "../api/removeShortUrl";
import { ShortUrl } from "../api/ShortUrl";
import { updateShortUrl } from "../api/updateShortUrl";

export function AdminPage() {
  interface IState {
    shortUrls: ShortUrl[];
    isFetching: boolean;
    isAdding?: boolean;
    isEditing?: boolean;
    longUrl?: string;
    longUrlError?: string;
    shortId?: string;
    shortIdError?: string;
  }

  const [data, setData] = useState({
    shortUrls: [],
    isFetching: false,
    longUrl: "",
    shortId: ""
  } as IState);

  useEffect(() => {
    connectShortUrls(data, setData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // see https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once

  const handleClickAdd = () => {
    console.log("handleClickAdd");
    setData({
      ...data,
      isAdding: true,
      shortId: "",
      longUrl: ""
    });
  };

  const handleClickEdit = (shortUrl: ShortUrl) => () => {
    console.log("handleClickEdit", shortUrl);
    setData({
      ...data,
      isEditing: true,
      shortId: shortUrl.id,
      longUrl: shortUrl.longUrl
    });
  };

  const handleClickRemove = (shortUrl: ShortUrl) => () => {
    console.log("handleClickRemove", shortUrl);
    if (window.confirm(`Remove shortUrl ${shortUrl.id}. Are you sure?`)) {
      removeShortUrl(shortUrl.id);
    }
  };

  const handleClickSubmit = (event: any) => {
    event.preventDefault();
    console.log("handleClickSubmit", data);
    if (data.isAdding) {
      if (!data.longUrl) {
        setData({ ...data, longUrlError: "Long url is required" });
        return;
      }
      setData({ ...data, longUrlError: "", shortIdError: "" });
      createShortUrl(data.longUrl, data.shortId).then((response: any) => {
        if (response.success) {
          window.alert(
            "Success! Created short url: " + response.shortUrl.shortUrl
          );
          setData(data => ({ ...data, isAdding: false, isEditing: false }));
        } else {
          setData({ ...data, shortIdError: response.message });
        }
      });
    } else if (data.isEditing) {
      if (!data.longUrl) {
        setData({ ...data, longUrlError: "Long url is required" });
        return;
      } else if (!data.shortId) {
        setData({ ...data, shortIdError: "shortId is required" });
        return;
      }
      setData({ ...data, longUrlError: "", shortIdError: "" });
      updateShortUrl(data.longUrl, data.shortId).then((response: any) => {
        if (response.success) {
          window.alert(
            "Success! Updated short url: " + response.shortUrl.shortUrl
          );
          setData({
            ...data,
            isAdding: false,
            isEditing: false,
            longUrl: "",
            shortId: ""
          });
        } else {
          setData({ ...data, shortIdError: response.message });
        }
      });
    }
  };

  const handleClickCancel = (event: any) => {
    event.preventDefault();
    console.log("handleClickCancel", data);
    setData({ ...data, isAdding: false, isEditing: false, longUrlError: "" });
  };

  const handleChange = (prop: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("handleChange", prop, e.currentTarget.value);
    setData({ ...data, [prop]: e.currentTarget.value });
  };

  console.log("data", data);
  return (
    <div>
      <h2>Short Url Admin</h2>
      <button className="App-add-button" onClick={handleClickAdd}>
        add
      </button>

      <table className="App-table">
        <thead>
          <tr>
            <th>Long Url</th>
            <th>Short Url</th>
            <th>Usage Count</th>
            <th>Last Usage</th>
            <th>Edit</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {data.shortUrls.map(su => (
            <tr key={su.id}>
              <td>
                <a href={su.longUrl} target="urltest">
                  {su.longUrl}
                </a>
              </td>
              <td>
                <a href={addProtocol(su.shortUrl)} target="urltest">
                  {su.shortUrl}
                </a>
              </td>
              <td>{su.usageCount}</td>
              <td>{new Date(su.used).toString()}</td>
              <td className="App-action" onClick={handleClickEdit(su)}>
                edit
              </td>
              <td className="App-action" onClick={handleClickRemove(su)}>
                remove
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CenteredModal isOpen={data.isAdding || data.isEditing}>
        <form className="App-form">
          <fieldset>
            <legend>{data.isEditing ? "Edit" : "Add"}:</legend>
            <label>
              Long Url:
              <input
                name="longUrl"
                type="text"
                value={data.longUrl}
                onChange={handleChange("longUrl")}
              />
              <a href={data.longUrl} target="smashcut-test-url">
                Test Link
              </a>
            </label>
            {data.longUrlError && (
              <div className="App-form-error">Error: {data.longUrlError}</div>
            )}
            <br />
            <label>
              Short Id (leave empty to auto-create id):
              <input
                name="shortId"
                type="text"
                value={data.shortId}
                onChange={handleChange("shortId")}
                disabled={data.isEditing}
              />
              <a
                href={makeShortUrl(data.shortId || "")}
                target="smashcut-test-url"
              >
                Test Link
              </a>
            </label>
            {data.shortIdError && (
              <div className="App-form-error">Error: {data.shortIdError}</div>
            )}
          </fieldset>
          <div className="App-buttons">
            <button onClick={handleClickSubmit}>Submit</button>
            <button onClick={handleClickCancel}>Cancel</button>
          </div>
        </form>
      </CenteredModal>
    </div>
  );
}
