import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AdminPage } from "./pages/AdminPage";
import { HomePage } from "./pages/HomePage";
import { AuthButton } from "./components/AuthButton";
import { PrivateRoute } from "./components/PrivateRoute";
import { LoginPage } from "./pages/LoginPage";
import { HeaderLogo } from "./components/HeaderLogo";

export default function App() {
  return (
    <Router>
      <div>
        <div className="App-header">
          <HeaderLogo />
        </div>
        <AuthButton />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/admin">
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          </Route>
          <Route path="/:shortId">
            <HomePage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
