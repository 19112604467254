// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB_2YIXYfH9yCGWdtfxzRkflMB5UzZySXc",
  authDomain: "smashcut-short-url-service.firebaseapp.com",
  databaseURL: "https://smashcut-short-url-service.firebaseio.com",
  projectId: "smashcut-short-url-service",
  storageBucket: "smashcut-short-url-service.appspot.com",
  messagingSenderId: "280020668550",
  appId: "1:280020668550:web:777d347ccdcd4eff334107"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export function onAuthStateChanged(listener: any) {
  return firebase.auth().onAuthStateChanged(listener);
}
