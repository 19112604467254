import { useHistory, useLocation } from "react-router-dom";
import { authManager } from "../api/authManager";
import React, { useState } from "react";

export function LoginPage() {
  interface IState {
    email: string;
    password: string;
    error?: string;
  }

  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({
    email: "",
    password: "",
    error: ""
  } as IState);

  const { from } = location.state || { from: { pathname: "/admin" } };
  const login = (e: any) => {
    e.preventDefault();
    authManager.authenticate(data.email, data.password, () => {
      history.replace(from);
    });
  };

  const handleChange = (prop: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("handleChange", prop, e.currentTarget.value);
    setData({ ...data, [prop]: e.currentTarget.value });
  };

  return (
    <div>
      <p>You must log in to view the page at {from.pathname}</p>
      <form>
        <label>
          Email:
          <input
            name="email"
            type="text"
            value={data.email}
            onChange={handleChange("email")}
          />
        </label>
        <label>
          Password:
          <input
            name="password"
            type="text"
            value={data.password}
            onChange={handleChange("password")}
          />
        </label>
        <button onClick={login}>Log in</button>
      </form>
    </div>
  );
}
