import "./index.css";
import "./api/initFirebase";
import App from "./App";
import Modal from "react-modal";
import React from "react";
import ReactDOM from "react-dom";
import { onAuthStateChanged } from "./api/initFirebase";

console.log("env", "%REACT_APP_FIREBASE_FUNCTIONS_HOST%", process.env);
Modal.setAppElement("#root");

onAuthStateChanged(() => {
  ReactDOM.render(<App />, document.getElementById("root"));
});
