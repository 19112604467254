import Modal from "react-modal";
import React from "react";

// @ts-ignore
export const CenteredModal = ({ children, isOpen, ...props }) => (
  <Modal
    style={{
      content: {
        backgroundColor: "#eee",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,.5)",
        zIndex: 100000
      }
    }}
    isOpen={isOpen}
    {...props}
  >
    {children}
  </Modal>
);
