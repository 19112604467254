import axios from "axios";
import { makeServiceUrl } from "./makeServiceUrl";
import { getIdToken } from "./getIdToken";

export const removeShortUrl = async (
  shortId: string
): Promise<any> => {
  try {
    const token = await getIdToken();
    const response = await axios.get(makeServiceUrl("/remove-short-url"), {
      params: {
        id: shortId
      },
      headers: { authorization: `Bearer ${token}` }
    });
    console.info("removeShortUrl response: ", response.data);
    return response.data;
  } catch (err) {
    console.error("removeShortUrl error: ", err);
    return { success: false, message: "Unexpected error" };
  }
};
