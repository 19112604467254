import axios from "axios";
import { getIdToken } from "./getIdToken";
import { makeServiceUrl } from "./makeServiceUrl";

export const updateShortUrl = async (
  longUrl: string,
  shortId: string
): Promise<any> => {
  try {
    const token = await getIdToken();
    const response = await axios.get(makeServiceUrl("/update-short-url"), {
      params: { url: longUrl, id: shortId },
      headers: { authorization: `Bearer ${token}` }
    });
    console.info("updateShortUrl response: ", response.data);
    return response.data;
  } catch (err) {
    console.error("Error: ", err);
    return { success: false, message: "Unexpected error" };
  }
};
