import * as firebase from "firebase/app";
import { onAuthStateChanged } from "./initFirebase";

export const authManager = {
  isAuthenticated: false,
  authenticate(email: string, password: string, cb: any) {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        authManager.isAuthenticated = true;
        cb();
      });
  },
  signout(cb: any) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        authManager.isAuthenticated = false;
        cb();
      });
  }
};

onAuthStateChanged((user?: any) => {
  authManager.isAuthenticated = !!user;
  console.log("onAuthStateChanged", user);
});
