import { useHistory } from "react-router-dom";
import { authManager } from "../api/authManager";
import React from "react";

export function AuthButton() {
  let history = useHistory();

  return authManager.isAuthenticated ? (
    <p>
      Welcome!{" "}
      <button
        onClick={() => {
          authManager.signout(() => history.push("/"));
        }}
      >
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  );
}
